import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Story = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant={'h5'}>
          We help brands provide better sales and customer service by analyzing, enriching the communication
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component={'p'} color={'text.secondary'} fontWeight={400}>
          For business owners, competition is real. The founders of Cognijiwa. AI have over a decade’s CRM consulting experience for some of the world’s best brands such as Swire Coca-Cola, New World Development and LVMH. 
            <br />
            <br />
            But our winning formula lies in constantly reinventing ourselves. As generative AI begins to disrupt the market, we see the opportunity to embrace the technology in applying it in CRM before our competitors do. 
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" justifyContent={'center'}>
            {[
              'https://cms.cognijiwa.ai/assets/866b0143-6547-470b-93f4-1d34f39b6d12.png',
              'https://cms.cognijiwa.ai/assets/433399a0-f8f2-4262-829a-0f2e9e3d17ca.png',
              'https://cms.cognijiwa.ai/assets/8d72fe7f-ccf1-40db-a7e9-2f1dcbcb4368.png',
              'https://cms.cognijiwa.ai/assets/8001d092-d476-4867-8022-57fe53c67ed7.png',
              'https://cms.cognijiwa.ai/assets/a93e7059-f0fa-4a67-87f6-baf5d1502251.png',
              'https://cms.cognijiwa.ai/assets/e8a05348-6f97-4519-aec1-51fe7eeb53bd.png',
            ].map((item, i) => (
              <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={item}
                  alt="..."
                  sx={{
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness() invert(0.7)'
                        : 'none',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
