import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const mock = [
  {
    name: 'Ruby Ku',
    title: 'CEO, Founder',
    linkedin: 'https://hk.linkedin.com/in/ruby-ku-3301a82b',
    avatar: 'https://cms.cognijiwa.ai/assets/e676cb20-d132-4488-8faa-70f96bcd6e92.jpg',
    about:
      'Ruby Ku is our CEO and the driving force behind Cogni. Ruby has spent more than ten years in the CRM world, creating new solutions and offering expert advice. Before starting Cogni, she led big projects for CRM and digital transformation projects, working with major companies. Ruby and our team bring a wealth of experience to make Cogni a leader in CRM innovation.',
  },
  {
    name: 'Alex Tsoi',
    title: 'Director of Product Development',
    linkedin: 'https://www.linkedin.com/in/alex-tsoi-08453b245',
    avatar: 'https://cms.cognijiwa.ai/assets/64c1c939-a33f-4d69-8919-19d511670020.jepg',
    about:
      'Alex is the Product Lead of cognijiwa.ai. With a wealth of experience in CRM, ERP, and IT consultancy, Alex is a well-rounded expert in the tech industry. He\'s especially good with AI and deep learning, which helps us create smart AI tools for CRM. Alex makes sure Cogni\'s products are leading the way in making CRM easier and more effective for businesses.',
  },
  {
    name: 'Yan Tse',
    title: 'Principle Consultant',
    linkedin: 'https://www.linkedin.com/in/yan-tse-041835140',
    avatar: 'https://cms.cognijiwa.ai/assets/d6dc893d-d765-40e2-a807-95df82d1a02b.jepg',
    about:
      'Yan is our Principal Consultant and a key figure at cognijiwa.ai. Yan has a strong background in CRM, ERP, and using automation and new technology to make businesses run smoother. Her skills in streamlining operations and her innovative thinking are at the heart of what we do at Cogni. Yan\'s expertise helps guide our product strategy and keeps us pushing forward with new ideas.',
  },
];

const Team = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
          align={'center'}
        >
          Our team
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Trust the professionals
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={Card}
              boxShadow={2}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box
                  component={Avatar}
                  src={item.avatar}
                  height={80}
                  width={80}
                />
                <Box marginTop={4}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    {item.about}
                  </Typography>
                  <Box marginTop={4}>
                    <IconButton href={item.linkedin} size={'small'} color={'primary'}>
                      <LinkedInIcon />
                    </IconButton>
                    {/* <IconButton size={'small'} color={'primary'}>
                      <GitHubIcon />
                    </IconButton>
                    <IconButton size={'small'} color={'primary'}>
                      <TwitterIcon />
                    </IconButton> */}
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
